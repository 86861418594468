import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { AppConfig } from 'app/app-config/app-config.service';
import { User } from 'app/main/apps/e-commerce/users/user.model';
import { LocalStorageService } from 'app/storage/localStorage.service';

@Injectable()
export class AuthService implements Resolve<any> {

  routeParams: any;
  //token: string;
  onTokenChanged: BehaviorSubject<any>;
  //user: any;
  onUserChanged: BehaviorSubject<any>;
  //isAuthenticated: boolean;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(
    private _httpClient: HttpClient,
    private _localStorageService: LocalStorageService
  ) {
    // Set the defaults
    this.onTokenChanged = new BehaviorSubject({});
    this.onUserChanged = new BehaviorSubject({});
  }
  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.routeParams = route.params;

    return new Promise((resolve, reject) => {

      Promise.all([
        this.getUser()
      ]).then(
        (data) => {
          resolve(data);
        },
        reject
      );
    });
  }

  /**
   * Login
   *
   * @param username
   * @param password
   * @returns {Promise<any>}
   */
  login(username, password): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(AppConfig.settings.apiServer.url + 'auth/login/', { username: username, password: password })
        .subscribe((response: any) => {          
          const token = response['token'];
          if(token){
            const user = response['user'];
            this.setLoggedUser(user, token);
            resolve(response);
          } else {
            reject("Login Error")
          }
        }, reject);
    });
  }

  /**
   * Logout
   *
   * @returns {Promise<any>}
   */
  logout(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._localStorageService.clearAll();
      this.onTokenChanged.next(undefined);
      this.onUserChanged.next(undefined);
      resolve(undefined);
    });
  }


  /**
   * Get user info
   *
   * @param username
   * @param password
   * @returns {Promise<any>}
   */
  getUser(): Promise<any> {
    return new Promise((resolve, reject) => {
      let user = this._localStorageService.getItem('user');
      this._httpClient.get(AppConfig.settings.apiServer.url + 'user/' + user.id)
        .subscribe((response: any) => {
          user = response;
          this._localStorageService.setItem('user', user);
          this.onUserChanged.next(user);
          resolve(response);
        }, reject);
    });
  }

  setLoggedUser(user, token){
    this._localStorageService.setItem('user', user);
    this._localStorageService.setItem('token', token);
    this._localStorageService.setItem('isAuthenticated', true);
    this.onTokenChanged.next(token);
    this.onUserChanged.next(user);
  }

  getLoggedUser(){
    return this._localStorageService.getItem('user');
  }

  isAuthenticated(){
    return this._localStorageService.getItem('isAuthenticated');
  }


  getRoles(): string[] {
    let loggedUser = this._localStorageService.getItem('user');
    switch (loggedUser.role) {
      case "SuperAdmin":
        return ["SuperAdmin", "Administrator", "Kupac"];
      case "Administrator":
        return ["Administrator", "Kupac"];
      default:
        return [];
    }
  }

}
