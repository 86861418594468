import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { IWebConfig } from './web-config.model';
import { AppConfig } from 'app/app-config/app-config.service';

@Injectable()
export class WebConfig {
    static settings: IWebConfig;
    constructor(private http: HttpClient) {}
    load() {
        return new Promise<void>((resolve, reject) => {
            this.http.get(AppConfig.settings.apiServer.url + 'web-config' ).toPromise().then((response : IWebConfig) => {
                WebConfig.settings = <IWebConfig>response;
               resolve();
            }).catch((response: any) => {
               reject(`Error: ${JSON.stringify(response)}`);
            });
        });
    }
}