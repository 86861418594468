export const locale = {
    lang: 'srb',
    data: {
        'NAV': {
            'APPLICATIONS': 'Aplikacije',
            'DASHBOARDS'  : 'Kontrolna tabla',
            'CALENDAR'    : 'Kalendar',
            'ECOMMERCE'   : '',
            'PRODUCTS'    : 'Proizvodi',
            'ORDERS'      : 'Narudžbine',
            'BUSINESSES': 'Firme',
            'BUSINESS': 'Profil firme',
            'MENU_POSITIONS': 'Lokacije',
            'MENUS': 'Meniji',
            'CATEGORIES': 'Kategorije',
            'USERS': 'Korisnici',
            'ACADEMY'     : 'Akademija',
            'MAIL'        : {
                'TITLE': 'Pošta',
                'BADGE': '15'
            },
            'MAIL_NGRX'        : {
                'TITLE': 'Pošta Ngrx',
                'BADGE': '13'
            },
            'CHAT'        : 'Ćaskanje',
            'FILE_MANAGER': 'Upravljanje fajlovima',
            'CONTACTS'    : 'Kontakti',
            'TODO'        : 'Zadaci',
            'SCRUMBOARD'  : 'SCRUM Tabla'
        }
    }
};
