import { Injectable } from '@angular/core';
import { Business } from 'app/main/apps/e-commerce/business/business.model';
import { User } from '../main/apps/e-commerce/users/user.model';
@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  public setItem(key: string, value: any) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
    
  public getItem(key: string){ 
    return JSON.parse(sessionStorage.getItem(key));
  }
  public removeItem(key:string) {
    sessionStorage.removeItem(key);
  }
  public clear(){
    sessionStorage.clear(); 
  }

  public getLoggedUser(): User{
    return JSON.parse(sessionStorage.getItem('user'));
  }

  public setBusiness(business: Business){
    let user = this.getLoggedUser();
    user.business = business;
    this.setItem('user', user);
  }

  public clearAll(){
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('isAuthenticated');
  }

}