import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { AppConfig } from 'app/app-config/app-config.service';
import { User } from 'app/main/apps/e-commerce/users/user.model';
import { LocalStorageService } from 'app/storage/localStorage.service';

@Injectable()
export class AuthorizationService {

  public AUTHORIZED_RESOURCES: any = {
    "UPDATE_BUSINESS": ["SuperAdmin", "Administrator", "Manager"],
    "CREATE_PRODUCT": ["SuperAdmin", "Administrator", "Manager"],
    "UPDATE_PRODUCT": ["SuperAdmin", "Administrator", "Manager"],
    "CREATE_MENU_POSITION": ["SuperAdmin", "Administrator", "Manager"],
    "UPDATE_MENU_POSITION": ["SuperAdmin", "Administrator", "Manager"]
  }

  ;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(
    private _localStorageService: LocalStorageService
  ) {

  }

  isAuthorized(key: string){
    let loggedUser = this._localStorageService.getItem('user');
    return loggedUser != null && this.AUTHORIZED_RESOURCES[key].indexOf(loggedUser.role)>-1
  }

}
