import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : 'Administracija',
        translate: 'NAV.APPLICATIONS',
        type     : 'group',
        roles    : ['SuperAdmin', 'Administrator'],
        icon     : 'apps',
        children : [
            {
                id        : 'orders',
                title     : 'Narudžbine',
                translate : 'NAV.ORDERS',
                type      : 'item',
                roles     : ['SuperAdmin', 'Administrator'],
                icon      : ' shopping_basket',
                url       : '/apps/e-commerce/orders',
                exactMatch: true
            },
            {
                id        : 'guarantees',
                title     : 'Produžene garancije',
                translate : 'NAV.ORDERS',
                type      : 'item',
                roles     : ['SuperAdmin', 'Administrator'],
                icon      : ' receipt',
                url       : '/apps/e-commerce/guarantees',
                exactMatch: true
            },
            {
                id       : 'users',
                title    : 'Korisnici',
                translate: 'NAV.USERS',
                type     : 'item',
                roles     : ['SuperAdmin', 'Administrator'],
                icon     : 'account_box',
                url      : '/apps/e-commerce/users'
            },
            {
                id        : 'import',
                title     : 'Import artikala',
                translate : 'NAV.ORDERS',
                type      : 'item',
                roles     : ['SuperAdmin', 'Administrator'],
                icon      : 'input',
                url       : '/apps/e-commerce/import',
                exactMatch: true
            },
            {
                id        : 'import-devices',
                title     : 'Import uređaja',
                translate : 'NAV.ORDERS',
                type      : 'item',
                roles     : ['SuperAdmin', 'Administrator'],
                icon      : 'input',
                url       : '/apps/e-commerce/import-devices',
                exactMatch: true
            }
            /*{
                id        : 'business',
                title     : 'My Business Profile',
                translate : 'NAV.BUSINESS',
                type      : 'item',
                roles     : ['SuperAdmin', 'Administrator', 'Manager'],
                icon      : 'meeting_room',
                url       : '/apps/e-commerce/business'
            },*/
        ]
    }
];
