export const locale = {
    lang: 'eng',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applications',
            'DASHBOARDS'  : 'Dashboards',
            'CALENDAR'    : 'Calendar',
            'ECOMMERCE'   : '',
            'PRODUCTS'    : 'Products',
            'ORDERS'      : 'Orders',
            'BUSINESSES': 'Businesses',
            'BUSINESS': 'Business',
            'MENU_POSITIONS': 'Locations',
            'MENUS': 'Menus',
            'CATEGORIES': 'Categories',
            'USERS': 'Users',
            'ACADEMY'     : 'Academy',
            'MAIL'        : {
                'TITLE': 'Mail',
                'BADGE': '25'
            },
            'MAIL_NGRX'        : {
                'TITLE': 'Mail Ngrx',
                'BADGE': '13'
            },
            'CHAT'        : 'Chat',
            'FILE_MANAGER': 'File Manager',
            'CONTACTS'    : 'Contacts',
            'TODO'        : 'To-Do',
            'SCRUMBOARD'  : 'Scrumboard'
        }
    }
};
